const scf = () => {
  // set a global jquery function for submitting the form
  $.simplecontactform = () => {
    const $forms = $(".form--backcall");

    if ($forms.length) {
      $forms.off("submit.scf");
      $forms.on("submit.scf", (e) => {
        e.preventDefault();
        const $form = $(e.currentTarget);
        const $submit = $("button:submit", $form);
        const data =
          $form.serialize() +
          "&" +
          $submit.attr("name") +
          "=" +
          $submit.attr("value");

        $.ajax({
          url: e.target.action,
          data,
          type: "post",
          success: (resp) => {
            $form.replaceWith($(resp));
            $.simplecontactform();
          },
          error: (jqXHR, textStatus, errorThrown) => {
            console.error(textStatus, errorThrown);
          },
        });
      });
    }
  };

  // and run it once
  $.simplecontactform();
};

export default scf;
