import "alpinejs";

import navbar from "./components/navbar";
import totop from "./components/to-top";
import loadMap from "./components/map";
import scf from "./components/backcall";
import slider from "./components/slider";
import zoom from "./components/zoom";

window.navbar = navbar;
window.totop = totop;
loadMap();
scf();
slider();
zoom();
