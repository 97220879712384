export default function () {
  return {
    open: false,

    header: {
      ["@keydown.window.escape"]() {
        this.open = false;
        window.dispatchEvent(new CustomEvent("body-close"));
      },
      ["@click.away"]() {
        this.open = false;
        window.dispatchEvent(new CustomEvent("body-close"));
      },
    },

    button: {
      ["@click"]() {
        if (this.open) {
          this.open = false;
          window.dispatchEvent(new CustomEvent("body-close"));
          return;
        }

        this.open = true;
        window.dispatchEvent(new CustomEvent("body-open"));
      },
    },

    navbar: {
      [":class"]() {
        return this.open ? "navbar--open" : "";
      },
    },
  };
}
