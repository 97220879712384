/* eslint $ */

export default function () {
  return {
    button: {
      ["@click.prevent"]() {
        $("html,body").animate({ scrollTop: 0 }, "slow");
      },
    },
  };
}
