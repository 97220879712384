export default function () {
  if (typeof map !== "undefined" && typeof ymaps !== "undefined") {
    var myMap, myPlace;
    ymaps.ready(function () {
      if (map.lat && map.lng && map.zoom) {
        myMap = new ymaps.Map("map", {
          center: [map.lat, map.lng],
          zoom: map.zoom,
          controls: ["zoomControl", "fullscreenControl"],
        });

        myPlace = new ymaps.Placemark([map.lat, map.lng], {});
        myMap.geoObjects.add(myPlace);
      }
    });
  }
}
