import { tns } from "../../../../node_modules/tiny-slider/src/tiny-slider";

export default function () {
  if ($(".slider").length) {
    tns({
      // autoHeight: true,
      autoplay: false,
      autoplayHoverPause: true,
      autoplayButtonOutput: false,
      navPosition: "bottom",
      controls: false,
      controlsText: [
        `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
</svg><span>prev</span>`,
        `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
</svg><span>next</span>`,
      ],
      responsive: {
        640: {
          controls: true,
        },
      },
    });
  }
}
