import "magnific-popup";

const zoom = () => {
  const options = {
    type: "image",
    tClose: "Закрыть (Esc)",
    tLoading: "Загрузка изображения #%curr%...",
    mainClass: "mfp-img-mobile",
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
      tCounter: "%curr% из %total%",
      tPrev: "Назад (Левая стрелка)",
      tNext: "Вперёд (Правая стрелка)",
    },
    image: {
      tError: '<a href="%url%">Изображение #%curr%</a> не может быть загружено',
      titleSrc: function (item) {
        return $(item.el[0].firstChild).attr("alt");
      },
    },
  };
  const items = $(".zoom");

  if (items.length) {
    items.magnificPopup(options);
  }
};

export default zoom;
